.overview {
   // border: blue 2px solid;
   display: grid;
   position: relative;
   padding-right: 20px;
   padding-left: 20px;

   .logo {
      height: 20vh;
      width: 150px;

      img {
         // margin-left: 20px;
         // margin-bottom: 20px;
         position: absolute;
         height: 40vh;
         bottom: 50%;
         left: 20%;
      }
   }

   .description {
      font-family: mrf-lemonberry-sans;
      margin-top: 3px;

      h1 {
         padding-top: 3px;
      }
   }

   @media only screen and (min-width: 0px) and (max-width: 576px) {
      grid-template-columns: 1fr;
      grid-template-rows: 0.5fr 1fr;

      .description {
         padding-top: 60px;

         h5 {
            padding-left: 15px;
            padding-right: 15px;
         }
      }

      .logo {
         // height: 20vh;
         // width: 150px;

         img {
            // margin-left: 20px;
            // margin-bottom: 20px;
            position: absolute;
            height: 35vh;
            bottom: 70%;
            top: 0;
            left: 0;
            right: 0;
            margin: auto;
            // left : 15%;
         }
      }
   }

   @media only screen and (min-width: 768px) {
    grid-template-columns: 2fr 2fr;
      .logo {
         // height: 20vh;
         // width: 150px;

         img {
            // margin-left: 20px;
            // margin-bottom: 20px;
            position: absolute;
            height: 25vh;
            bottom: 65%;
            left: 15%;
            // border: 2px solid red;
         }
      }
   }

   @media only screen and (min-width: 992px) {
      grid-template-columns: 2fr 3fr;
      padding-left: 14px;

      .description {
         padding-right: 40px;
      }

      .logo {
         // height: 20vh;
         // width: 150px;

         img {
            // margin-left: 20px;
            // margin-bottom: 20px;
            position: absolute;
            height: 45vh;
            bottom: 0%;
            left: 10%;
            width: 30vw;
         }
      }
   }
}
