@font-face {
  font-family: mrf-lemonberry-sans;
  src: url(Fonts/MrfLemonberrySans-P5ar.otf);
  font-weight: bold;
}

@font-face {
  font-family: futura-std-light-oblique;
  src: url(Fonts/Futura-Std-Light-Oblique.otf);
}

.App {
   text-align: center;
   /* border: red 2px solid; */
   height: 100vh;


}

