.streaming-services {
  // border: solid 2px red;
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;

  img {
    padding-top: 5px;
    height: 40px;
  }
 
}

