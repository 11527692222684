.hero {
  //  border: red 2px solid;
   width: 100%;
   background-color: orange;

   img {
      width: 100%;
      height: 65vh;
   }
   @media only screen and (max-width: 600px) {
      img {
         height: 49vh;
      }
   }
}
